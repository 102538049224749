import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class Community extends React.Component {
	render() {
		const siteTitle = 'Community'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Community</h3>
								<p>
									<strong>THE BIG ISSUE CHALLENGE</strong>
								</p>
								<p>
									On 30 November 2018, WSC Group took on the&nbsp;
									<b>#TheBigIssueChallenge</b>&nbsp;alongside The Big Issue
									street vendors in Sydney.
								</p>
								<p>
									Together we sold 54 magazines in the space of just one hour
									which helped put sales earnings of $378 towards helping
									homeless and disadvantaged people.
								</p>
								<p>
									Every magazine sold helps a homeless or disadvantaged person
									who is&nbsp;<b>#ProudlyWorking</b>.
								</p>
								<ul>
									<li>
										{' '}
										<b>Facebook: </b>
										<a
											href="https://www.facebook.com/bigissueaustralia/"
											target="_blank"
											rel="noopener noreferrer"
										>
											{' '}
											facebook.com/bigissueaustralia
										</a>
									</li>
									<li>
										{' '}
										<b>Twitter: </b>
										<a
											href="https://twitter.com/thebigissue"
											target="_blank"
											rel="noopener noreferrer"
										>
											{' '}
											@thebigissue
										</a>
									</li>
									<li>
										{' '}
										<b>LinkedIn:</b>
										<a
											href="https://www.linkedin.com/company/big-issue/"
											target="_blank"
											rel="noopener noreferrer"
										>
											{' '}
											linkedin.com/company/big-issue
										</a>
									</li>
									<li>
										{' '}
										<b>Instagram: </b>
										<a
											href="https://www.instagram.com/bigissueaustralia/"
											target="_blank"
											rel="noopener noreferrer"
										>
											{' '}
											@bigissueaustralia
										</a>
									</li>
									<li>
										{' '}
										<b>Website: </b>
										<a
											href="https://www.thebigissue.org.au/"
											target="_blank"
											rel="noopener noreferrer"
										>
											{' '}
											www.thebigissue.org.au{' '}
										</a>
									</li>
								</ul>
								<div class="row">
									<div className="col-md-6 col-sm-6 col-12">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/28acda25331e0598d0a238c4e2e5a22680194e53/3dfa8/img/wsc/secret-santa.jpeg"
											className="img-responsive about-img"
											alt="WSC Community Christmas"
										/>
									</div>
									<div className="col-md-6 col-sm-6 col-12">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/img/wsc/0d525c0fca1910d8d572721ba8f301b5d3f80377/tbi_challenge_1.jpg"
											className="img-responsive about-img"
											alt="WSC Community Christmas"
										/>
									</div>
									<div className="col-md-6 col-sm-6 col-12">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/img/wsc/279a0604c38cd2b5d02aa451d0a40645a93e7ebb/tbi_challenge_2.jpg"
											className="img-responsive about-img"
											alt="WSC Community Christmas"
										/>
									</div>
									<div className="col-md-6 col-sm-6 col-12">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/img/wsc/aed142f5d4756a5295edd0fe47dbdef8663a73c5/tbi_challenge_3.jpg"
											className="img-responsive about-img"
											alt="WSC Community Christmas"
										/>
									</div>
									<div className="col-md-6 col-sm-6 col-12">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/img/wsc/5da9205ff09422bc2fdfc3254889ce31bef74ad1/tbi_challenge_4.jpg"
											className="img-responsive about-img"
											alt="WSC Community Christmas"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Community

export const CommunityPageQuery = graphql`
	query CommunityPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
